// override

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important;
}
.link {
  cursor: pointer;
}
.right-status {
  text-align: right;
}
.status-progress {
  color: orange;
  font-weight: bold !important;
}
.status-done {
  color: green;
  font-weight: bold !important;
}
.MuiTabs-centered {
  justify-content: center;
  overflow-x: auto;
  background-color: #dff9f3;
}
.MuiTabs-flexContainer {
  display: block !important;
  text-align-last: center;
}

.container-widget-line-info {
  margin: 1px;
  @media (max-width: 767px) {
    display: contents;
  }
}
.container-widget-info-img {
  height: 60px;
  width: 60px;
  display: flex;
}
.container-widget-info {
  cursor: pointer;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #11826b;
  margin: 1px;
  @media (max-width: 767px) {
    height: 100px;
    width: 100%;
  }
  &:hover {
    opacity: 0.9;
  }
}

.container-img-info {
  width: auto;
  height: 90%;
  margin: auto;
  @media (max-width: 767px) {
    width: auto;
    height: 90%;
  }
}
.container-label-info {
  width: 70%;
  text-align-last: center;
  padding: 0 5px;
  text-align: center;
}
.span-label-info {
}
.size-24 {
  font-size: 24px;
  color: #d5b032;
  font-weight: bold;
}
