$width-bar: 180px;
@import "./assets/sass/variables";
.container-app-right {
  height: 100%;
}
.App {
  height: 100%;
}
.text-color {
  color: #134b8c;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
/* new class here */
.App-logo-paused {
  animation-play-state: paused;
  background-color: $yellow-700;
}
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.appContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #f3f3f3 6px
    ),
    repeating-linear-gradient(#04661f36, #60826a14);
}
.all-width {
  width: 100%;
}

@media (max-width: 767px) {
  input[type="text"] {
    font-size: 12px;
  }

  input[type="date"] {
    width: 110px;
  }

  label {
    font-size: 12px !important;
    color: red;
  }
  .MuiInputBase-root {
    font-size: 12px !important;
  }
}
.MuiSelect-select.MuiSelect-select {
  min-width: 100px;
  min-width: 130px;
}
.makeStyles-root-2 {
}

.lines {
  padding: 2%;
  &__line {
    display: flex;
    margin: 5px;
    @media (max-width: 767px) {
      margin: 3px;
    }
  }
  &__last-line {
  }
  &__footer {
    display: flex;
    margin: 20px;
    justify-content: end;
    @media (max-width: 767px) {
      margin: 8px;
    }
  }
  &__footer-action {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #046753;
    padding: 5px;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
    min-width: 120px;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 767px) {
      min-width: 80px;
      font-size: 12px;
    }
  }
  &__title {
    cursor: default;
    background: #046753;
    color: #def7f2;
    box-shadow: 0px 1px 2px #11826b4d;
    margin-right: 5px;
    border-radius: 5px;
    width: 30%;
    padding: 5px;
    font-weight: bold;
    @media (max-width: 767px) {
      padding: 3px;
      font-size: 12px;
    }
  }

  &__title-clicked {
    color: #034638;
    margin-right: 5px;
    border-radius: 5px;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    background: #0c00ff14;
    margin: 3px 5px;
    &:hover {
      color: #046753;
      text-decoration: underline;
    }
    @media (max-width: 767px) {
      padding: 3px;
      font-size: 12px;
    }
  }

  &__desc {
    width: 70%;
    color: #046753;
    background-color: #0000000f;
    border-radius: 4px;
    padding: 5px;
    cursor: default;
    @media (max-width: 767px) {
      padding: 3px;
      font-size: 12px;
    }
  }
  &__desc-clickable {
    color: #046753;
  }
  &__last-line-desc {
    width: 50%;
    padding: 5px;
    @media (max-width: 767px) {
      padding: 3px;
      font-size: 12px;
    }
  }
  &__last-line-button {
    width: 20%;
    justify-content: center;
    text-align: center;
    display: flex;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
.modal {
  &__header {
    background: #1b4b43;
    height: 50px;
    &-title {
      text-align: center;
      color: #c4d0ce;
      padding: 15px 28px;
      font-weight: 600;
      font-size: 15px;
    }
    &-close {
    }
  }
  &__body {
    height: calc(100% - 120px);
    background: #1b4b4342;
    overflow-y: auto;
    &-description {
      color: #9d9d9d;
      padding: 0 28px 20px 28px;
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
    &--input {
      padding: 0px 15px;
      display: grid;
      &-label {
        font-size: 12px;
        margin-bottom: 0px;
      }
      &-input {
        height: 30px;
        width: 100%;
        padding-left: 10px;
        position: relative;
        font-size: 12px;
      }
      &-inputDesc {
        height: 30px;
        width: 100%;
        padding-left: 10px;
        position: relative;
        font-size: 20px;
      }
      &-container {
        height: 35px;
        position: relative;
      }
    }
    &-comment {
    }
  }
  &__footer {
    background: #1b4b43;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-close {
    }
  }
  &__button {
    cursor: pointer;
    overflow: auto;
    margin: 5px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 0 0 #0b839b;
    background-color: #0fa6c4;
    color: #fafafa;
    font-weight: 700;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    padding: 10px;
    width: 100px;
    text-align: center;
    &:hover {
      background-color: #0fa6c4c4;
    }
  }
}

.footer-modal {
  height: 70px;
  justify-content: space-evenly;
  display: flex;
  background: #1b4b43;
}
.container-header {
  // background-color: #1a6f90 !important;
  background-color: #046753 !important;
  box-shadow: 0px 0px 4px #2d2c2c;
}
.bloc-inside {
  box-shadow: 0px 0px 4px #2d2c2c;
  display: block;
  background: #0c00ff14;
  &__item {
    color: #def7f2;
    width: auto;
    height: auto;
    margin: 10px;
    padding: 8px;
    font-weight: bold;
    align-items: center;
    font-size: 12px;
    display: inline-block;
    border-radius: 5px;
    /* padding: 5px; */
    background-color: #114a8b !important;
    cursor: pointer;
    @media (max-width: 767px) {
      font-size: 8px;
      margin: 4px;
      padding: 2px 8px;
    }
  }
}
.img-small {
  height: 50px;
  width: 50px;
}
.MuiTab-wrapper {
  font-size: 10px;
}
.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  font-size: 32px;
}
.padding2 {
  padding: 2%;
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
  span {
    font-weight: normal;
    font-size: 0.7rem;
    color: gray;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 20px;
    line-height: 5px;
  }
}
.att-btn {
  color: #046753;
  width: 24px;
  cursor: pointer;
}

.accordion {
  &__title {
    font-weight: bold !important;
    font-size: 14px;
    flex-basis: 33.33%;
    align-self: center;
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
  }
  &__subtitle {
    width: 33%;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    @media (max-width: 767px) {
      font-size: 10px !important;
    }
  }
  &__subtitle1 {
    background: #406464;
    padding: 0 8px;
    border-radius: 12px;
    width: 25%;
    text-align-last: center;
    color: white;
    font-weight: bold !important;
    align-self: center;
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 767px) {
      font-size: 10px !important;
    }
  }
}
.MuiAccordionSummary-content {
  justify-content: space-between;
}
.container-title {
  margin: 20px 5px;
  padding: 4px 0;
  font-size: 22px;
  color: #11826b;
  border-bottom: 1px solid #049c7e;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.drag-container {
  position: relative;
  border: 3px grey dashed;
  height: 60px;
  margin: 10px;
  background-color: #f7f7f7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type="file"] {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
