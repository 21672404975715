.container-wrapper {
  background-color: white;
  border: 1px solid #049c7e38;
  max-width: 1200px;
  width: 80%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.container-body {
  background-image: linear-gradient(0deg, #fff, #04675326);
  //background-color: #ecf8fd;
  padding: 0 5%;
  overflow: auto;
  height: calc(100% - 104px);
  // background-repeat: round;
}
.container-subtitle {
  margin: 20px 5px;
  color: #114a8b;
  font-size: 15px;
}
.container-list-benif {
  padding: 10px;
}
.container-item-benif {
  cursor: pointer;
  overflow: auto;
  margin: 5px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 0 0 #039c7d;
  background-color: #cfeee8;
  color: #046753;
  font-weight: 700;
  transition: all 0.2s;
  padding: 10px;
  &:hover {
    background-color: #a7e6d9;
  }
}
.container-benif-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #046753;
  box-shadow: 1px 1px 3px black;
  margin: 3px;
  -moz-text-align-last: center;
  text-align-last: center;
  font-size: 12px;
  cursor: pointer;
  float: right;
  color: #ecfdf9;
}
.container-benif-label {
  float: left;
}
.container-button-add {
  background-color: #046753;
  box-shadow: 0 4px 0 0 #006450;
  color: #ecfdf9;
  &:hover {
    background-color: #049c7e;
  }
}

.doctor {
  &_container-widget {
    background-color: #039c7d1a;
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    box-shadow: 1px 1px 2px #a0cdc2;
    @media (max-width: 767px) {
      height: 30px;
      width: 30px;
      padding: 5px;
    }
  }
  &_container-label {
    text-align-last: center;
    color: #046753;
    margin-top: 0px;
    font-size: 8px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &_container-img {
    height: 30px;
    width: 30px;
    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
    }
  }
  &_flex {
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: #0467530f;
    padding: 20px;
  }
  &_width {
    width: 100%;
    position: relative;
  }
  &_margin {
    margin: 0 23px;
    @media (max-width: 767px) {
      margin: 0 15px;
    }
  }
  &_input {
    width: 100%;
    border: 1px solid #046753;

    padding: 5px;
    &:focus-visible {
      outline: none;
    }
  }
  &_icon-container {
    position: absolute;
    right: 9px;
    top: 4px;
    width: 20px;
    height: 20px;
  }
  &_icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #046753;
  }
  &_card {
    display: flex;
    position: relative;
    width: 100%;
    @media (max-width: 767px) {
      min-height: 110px;
    }
    &-photo {
      height: 100px;
      width: 100px;
      @media (max-width: 767px) {
        height: 60px;
        width: 60px;
        align-self: center;
      }
    }
    &-detail {
      align-self: center;
      margin-left: 30px;
    }
    &-title {
      font-size: 28px;
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
    &-sutitle {
    }
    &-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer;
      color: #006450;
    }
  }
}
