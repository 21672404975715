.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1;
}
.MuiInputBase-root {
  height: 30px;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.content-modal {
  height: 300px;
  @media (max-width: 767px) {
    height: 260px;
  }
}
.text-area {
  display: grid;
  margin: 10px 0px;
}
.result_container {
  margin-top: 15px;
  padding: 5% 10px;
  background: #f7f7f7;
  &--title {
    font-weight: bold;
    text-align: center;
  }
}
.margin-text {
  margin: 10px;
}
.margin-date {
  margin: 10px 0;
}
