.container-wrapper {
  background-color: white;
  box-shadow: 2px 2px 10px #d8d8d8;
  max-width: 1200px;
  width: 80%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.container-body {
  padding: 0 5%;
  overflow: auto;
  height: calc(100% - 104px);
}
.container-return-action {
  font-size: 18px;
  padding: 5px;
  font-weight: bold;
  color: #046753;
  cursor: pointer;
  color: #ecfdf9;
  display: flex;
  background: #046753e3;
  align-items: center;
  &:hover {
    color: #049c7e;
  }
  @media (max-width: 767px) {
    font-size: 15px;
  }
}
.bold {
  font-weight: bold;
}
.container-subtitle {
  margin: 20px 5px;
  color: #114a8b;
  font-size: 15px;
  @media (max-width: 767px) {
    margin: 7px 5px;
    font-size: 12px;
  }
}
.container-right-actions {
  color: #046753;
  font-weight: bold;
  margin: 32px 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  font-weight: 600;
  width: 28rem;
  max-height: 6.5rem;
  border-radius: 1rem;
  background-color: white;
  cursor: pointer;
  border: 1px solid #e1eaf0;
  @media (max-width: 767px) {
    font-size: 12px;
    width: 18rem;
    margin: 24px 1px;
  }
  &_bloc-left {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #04675314;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    &_inside {
      width: 24px;
      height: 24px;
      border-radius: 0.3rem;
      overflow: hidden;
      background-color: #11826b;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }
  &:hover {
    box-shadow: 0 16px 24px rgba(51, 51, 51, 0.08);
  }
}
.container-filters-top {
  overflow: auto;
  height: 70px;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  @media (max-width: 767px) {
    height: 70px;
  }
}
.container-filter-top {
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  background-color: #00000014;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  color: #036753;
  text-align: center;
  border: 1px solid #c9c9c9;
  &:hover {
    background-color: #00000021 !important;
  }
  @media (max-width: 767px) {
    min-width: 55px;
    font-size: 10px;
    width: 25%;
    padding: 0 3px;
    display: grid;
  }
}
.container-filter-top-actif {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  background-color: #11826b;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  @media (max-width: 767px) {
    min-width: 55px;
    font-size: 10px;
    width: 25%;
    padding: 0 3px;
    display: grid;
  }
}

.container-tabs-top {
  overflow: auto;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  @media (max-width: 767px) {
  }
}
.container-tab-top {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  color: #036753;
  text-align: center;
  border: 1px solid #c9c9c9;
  font-size: 12px;
  padding: 10px;
  line-height: 1;
  background-color: #00000014;
  &:hover {
    background-color: #00000021 !important;
  }
  @media (max-width: 767px) {
    min-width: 55px;
    width: 25%;
    font-size: 10px;
    padding: 5px;
  }
}
.container-tab-top-actif {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  background-color: #11826b;
  color: white;
  text-align: center;
  border: 1px solid #036753;
  font-size: 12px;
  padding: 10px;
  line-height: 1;
  @media (max-width: 767px) {
    min-width: 55px;
    width: 25%;
    font-size: 10px;
    padding: 5px;
  }
}
.min-height-40 {
  min-height: 200px;
  background-color: #ecfdf9;
}
.small-text {
  @media (max-width: 767px) {
    font-size: 8px !important;
  }
}
.container-widget-line {
  display: flex;
  margin: 1%;
}
.container-widget {
  background-color: #039c7d1a;
  cursor: pointer;
  height: 200px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-shadow: 1px 1px 2px #a0cdc2;
  @media (max-width: 767px) {
    height: 100px;
  }
  &:hover {
    background-color: #039c7d9e !important;
  }
}
.container-img {
  height: 100px;
  width: 100px;
  @media (max-width: 767px) {
    height: 60px;
    width: 60px;
  }
}
.container-label {
  text-align-last: center;
  color: #046753;
  margin-top: 10px;
  @media (max-width: 767px) {
    font-size: 8px;
  }
}
.hidden {
  .MuiBadge-badge {
    visibility: hidden;
  }
}
.badge-green {
  .MuiBadge-badge {
    background-color: orange;
    color: white;
  }
}
