.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1;
}
.MuiInputBase-root {
  height: 30px;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.content-modal {
  height: 300px;
  @media (max-width: 767px) {
    height: 300px;
  }
}

.filter__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  &--item {
    background-color: #e7e7e7;
    height: 50px;
    margin: 5px;
    display: flex;
    padding: 5px;
    align-items: center;
    width: 100px;
    justify-content: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  &-actif {
    background-color: #ade1d8;
    font-weight: bold;
  }
}
.content-select {
  width: 200px;
}
.select__container {
  background-color: #f9f9f9;
  height: 134px;
}

.Autocomplete {
  .MuiInputBase-root {
    height: 50px;
  }
}
.select__container_fix {
  background-color: #f9f9f9;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.input-min-width {
  min-width: 95px !important;
}
