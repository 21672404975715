.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1;
}
.MuiInputBase-root {
  height: 30px;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.content-modal {
  height: 300px;
  padding-top: 10px;
  @media (max-width: 767px) {
    height: 260px;
  }
}
.urgent-contact {
  overflow: auto;
  margin: 5px 0;
  border-radius: 8px;
  /* box-shadow: 0px 1px 1px #0467532b; */
  border: 2px solid #04675314;
  background-color: #ecfdf9;
  font-weight: 700;
  transition: all 0.2s;
  padding: 10px;
}

.margin-left {
  margin-left: 20px !important;
}
