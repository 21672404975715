.report-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &__left {
    padding-top: 50px;
    width: 10%;
    z-index: 1;
    &--button {
      display: flex;
      color: #046753;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin: 32px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__right {
    width: 0%;
    &--button {
      max-width: 150px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      margin: 32px;
      border: 1px solid #046753;
      color: #046753;
      box-sizing: border-box;
      background-color: white;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  &__body {
    overflow: auto;
    width: 100%;
    &--header {
      background: white;
      margin-right: 10%;
      padding: 32px;
      margin-top: 32px;
      margin-bottom: 32px;
      border-radius: 2px;
      &-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: var(--accent-950);
        margin: 32px auto;

        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
      &-bloc {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--accent-950);
        margin: 4px 0px;
      }
      &-cell {
        display: flex;
        width: 100%;
        justify-content: space-around;
        border: 1px solid #333333;
        &:not(:last-child) {
          border-bottom: none;
        }
      }
      &-halfcell {
        width: 50%;
        border-right: 1px solid #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        &:last-child {
          border-right: none;
        }

        @media (max-width: 767px) {
          padding: 8px;
        }
      }
    }

    &--legend {
      margin-top: 30px;
      &-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--accent-950);
        margin: 15px 0px;
      }
      &-symbol {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--accent-950);
        margin: 4px 0px;
        display: flex;
      }
    }
    &__footer {
      padding-bottom: 32px;
      margin: 32px 0;
    }
    &--text {
      font-size: 14px;
      margin-left: 10px;
      @media (max-width: 767px) {
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }
  &__footer {
    padding-bottom: 32px;
    margin: 32px 0;
  }
}
.h1-report {
  font-size: 24px;
}
.h2-report {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--accent-950);
  margin-top: 2px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.h2-module {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--accent-950);
  margin-top: 2px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.h3-report {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 22px;
  color: var(--accent-950);
  margin: 32px 0px 12px;
  @media (max-width: 1281px) {
    margin: 24px 0px 12px;
  }

  @media (max-width: 767px) {
    margin: 18px 0px 6px;
    font-size: 16px;
  }
}
.p-report {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  color: var(--accent-950);
  align-self: center;
  font-size: 0.875rem;

  @media (max-width: 767px) {
    font-size: 9px;
  }
}
.text-center {
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: 220px;
}
.div-center {
  align-self: center;
  padding: 12px 0;
  max-width: 25%;

  @media (max-width: 767px) {
    padding: 8px 0;
  }
}
.report-content {
  margin-top: 40px;

  @media (max-width: 767px) {
    margin-top: 10px;
  }
  &__header {
    background: #046753;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: white;
    padding: 15px;
    direction: rtl;
    letter-spacing: 0.4px;
    border-bottom: 1px solid #333333;

    @media (max-width: 767px) {
      font-size: 12px;
      padding: 10px;
    }
  }
  &__body {
    display: flex;
    border: 1px solid #333333;
    border-top: none;
    &--text {
      width: 80%;
      padding: 20px;
      border-right: 1px solid #333333;

      @media (max-width: 767px) {
        padding: 8px;
        font-size: 12px;
      }
    }
    &--value {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__footer {
    background: var(--primary-50);
    border: 1px solid #333333;
    border-top: none;
    padding: 20px;

    @media (max-width: 767px) {
      padding: 8px;
      font-size: 12px;
    }
  }
}
.icon-status {
  width: 35px;
  height: 35px;
  align-self: center;
  margin: 0;
}
.icon-exit {
  width: 15px;
  height: 15px;
  align-self: center;
  margin-left: 5px;
}
.icon-legend {
  width: 25px;
  height: 25px;
  align-self: center;
  margin-right: 4px;
}
.icon-NA {
  font-size: 12px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-NA-status {
  width: 35px;
  align-self: center;
  font-size: 15px;
  font-weight: bold;
}
.opacity {
  opacity: 0;
}
.apexcharts-toolbar {
  display: none;
}
.chart {
  justify-content: center;
}
.width_button {
  float: right;
  min-width: 150px;
  background-color: var(--primary-850);
  font-family: Roboto;
  font-weight: 600;
  color: white;
  &:hover {
    background-color: var(--primary-870);
  }
}
.center-spinner {
  margin: auto;
}
.hide-graph {
  opacity: 0;
  position: absolute;
}
.logo-sihati {
  color: #e0b430f2 !important;
  font-weight: bold;
}
.download-button {
  background: white;
  padding: 5px;
  box-shadow: 1px 1px 1px #036753;
  max-width: 100px;
}
