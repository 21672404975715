body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container{
  top:0;
  bottom:0;
   left:0; right:0;
   position: absolute;
  width: 100%;
  height: 100%;
}

.container-app-right {
  height: 100%; }

.App {
  height: 100%; }

.text-color {
  color: #134b8c; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

/* new class here */
.App-logo-paused {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  background-color: #ffebcd; }

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.appContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #f3f3f3 6px), repeating-linear-gradient(#04661f36, #60826a14); }

.all-width {
  width: 100%; }

@media (max-width: 767px) {
  input[type="text"] {
    font-size: 12px; }
  input[type="date"] {
    width: 110px; }
  label {
    font-size: 12px !important;
    color: red; }
  .MuiInputBase-root {
    font-size: 12px !important; } }

.MuiSelect-select.MuiSelect-select {
  min-width: 100px;
  min-width: 130px; }

.lines {
  padding: 2%; }
  .lines__line {
    display: flex;
    margin: 5px; }
    @media (max-width: 767px) {
      .lines__line {
        margin: 3px; } }
  .lines__footer {
    display: flex;
    margin: 20px;
    justify-content: end; }
    @media (max-width: 767px) {
      .lines__footer {
        margin: 8px; } }
  .lines__footer-action {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #046753;
    padding: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
    min-width: 120px;
    text-align: center; }
    .lines__footer-action:hover {
      text-decoration: underline; }
    @media (max-width: 767px) {
      .lines__footer-action {
        min-width: 80px;
        font-size: 12px; } }
  .lines__title {
    cursor: default;
    background: #046753;
    color: #def7f2;
    box-shadow: 0px 1px 2px #11826b4d;
    margin-right: 5px;
    border-radius: 5px;
    width: 30%;
    padding: 5px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .lines__title {
        padding: 3px;
        font-size: 12px; } }
  .lines__title-clicked {
    color: #034638;
    margin-right: 5px;
    border-radius: 5px;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    background: #0c00ff14;
    margin: 3px 5px; }
    .lines__title-clicked:hover {
      color: #046753;
      text-decoration: underline; }
    @media (max-width: 767px) {
      .lines__title-clicked {
        padding: 3px;
        font-size: 12px; } }
  .lines__desc {
    width: 70%;
    color: #046753;
    background-color: #0000000f;
    border-radius: 4px;
    padding: 5px;
    cursor: default; }
    @media (max-width: 767px) {
      .lines__desc {
        padding: 3px;
        font-size: 12px; } }
  .lines__desc-clickable {
    color: #046753; }
  .lines__last-line-desc {
    width: 50%;
    padding: 5px; }
    @media (max-width: 767px) {
      .lines__last-line-desc {
        padding: 3px;
        font-size: 12px; } }
  .lines__last-line-button {
    width: 20%;
    justify-content: center;
    text-align: center;
    display: flex;
    font-weight: bold;
    align-items: center;
    cursor: pointer; }
    @media (max-width: 767px) {
      .lines__last-line-button {
        font-size: 12px; } }

.modal__header {
  background: #1b4b43;
  height: 50px; }
  .modal__header-title {
    text-align: center;
    color: #c4d0ce;
    padding: 15px 28px;
    font-weight: 600;
    font-size: 15px; }

.modal__body {
  height: calc(100% - 120px);
  background: #1b4b4342;
  overflow-y: auto; }
  .modal__body-description {
    color: #9d9d9d;
    padding: 0 28px 20px 28px; }
    @media (max-width: 767px) {
      .modal__body-description {
        font-size: 10px; } }
  .modal__body--input {
    padding: 0px 15px;
    display: grid; }
    .modal__body--input-label {
      font-size: 12px;
      margin-bottom: 0px; }
    .modal__body--input-input {
      height: 30px;
      width: 100%;
      padding-left: 10px;
      position: relative;
      font-size: 12px; }
    .modal__body--input-inputDesc {
      height: 30px;
      width: 100%;
      padding-left: 10px;
      position: relative;
      font-size: 20px; }
    .modal__body--input-container {
      height: 35px;
      position: relative; }

.modal__footer {
  background: #1b4b43;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center; }

.modal__button {
  cursor: pointer;
  overflow: auto;
  margin: 5px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 0 0 #0b839b;
  background-color: #0fa6c4;
  color: #fafafa;
  font-weight: 700;
  transition: all 0.2s;
  padding: 10px;
  width: 100px;
  text-align: center; }
  .modal__button:hover {
    background-color: #0fa6c4c4; }

.footer-modal {
  height: 70px;
  justify-content: space-evenly;
  display: flex;
  background: #1b4b43; }

.container-header {
  background-color: #046753 !important;
  box-shadow: 0px 0px 4px #2d2c2c; }

.bloc-inside {
  box-shadow: 0px 0px 4px #2d2c2c;
  display: block;
  background: #0c00ff14; }
  .bloc-inside__item {
    color: #def7f2;
    width: auto;
    height: auto;
    margin: 10px;
    padding: 8px;
    font-weight: bold;
    align-items: center;
    font-size: 12px;
    display: inline-block;
    border-radius: 5px;
    /* padding: 5px; */
    background-color: #114a8b !important;
    cursor: pointer; }
    @media (max-width: 767px) {
      .bloc-inside__item {
        font-size: 8px;
        margin: 4px;
        padding: 2px 8px; } }

.img-small {
  height: 50px;
  width: 50px; }

.MuiTab-wrapper {
  font-size: 10px; }

.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  font-size: 32px; }

.padding2 {
  padding: 2%; }
  @media (max-width: 767px) {
    .padding2 {
      font-size: 12px !important; } }
  .padding2 span {
    font-weight: normal;
    font-size: 0.7rem;
    color: gray;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 20px;
    line-height: 5px; }

.att-btn {
  color: #046753;
  width: 24px;
  cursor: pointer; }

.accordion__title {
  font-weight: bold !important;
  font-size: 14px;
  flex-basis: 33.33%;
  align-self: center; }
  @media (max-width: 767px) {
    .accordion__title {
      font-size: 12px !important; } }

.accordion__subtitle {
  width: 33%;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: center; }
  @media (max-width: 767px) {
    .accordion__subtitle {
      font-size: 10px !important; } }

.accordion__subtitle1 {
  background: #406464;
  padding: 0 8px;
  border-radius: 12px;
  width: 25%;
  text-align-last: center;
  color: white;
  font-weight: bold !important;
  align-self: center;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  @media (max-width: 767px) {
    .accordion__subtitle1 {
      font-size: 10px !important; } }

.MuiAccordionSummary-content {
  justify-content: space-between; }

.container-title {
  margin: 20px 5px;
  padding: 4px 0;
  font-size: 22px;
  color: #11826b;
  border-bottom: 1px solid #049c7e;
  font-weight: bold; }
  @media (max-width: 767px) {
    .container-title {
      font-size: 14px; } }

.drag-container {
  position: relative;
  border: 3px grey dashed;
  height: 60px;
  margin: 10px;
  background-color: #f7f7f7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .drag-container input[type="file"] {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    @media (max-width: 767px) {
      .drag-container input[type="file"] {
        font-size: 12px; } }

.login {
  width: 100%; }
  .login__header {
    height: 70px;
    background-color: #ecfdf9; }
  .login__image {
    text-align: center; }
  .login__body {
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center; }
  .login__submit {
    cursor: pointer; }
  .login__form {
    background-color: white;
    width: 400px;
    height: 400px;
    padding: 20px;
    box-shadow: 2px 2px 10px #d8d8d8; }

.container-wrapper {
  background-color: white;
  border: 1px solid #049c7e38;
  max-width: 1200px;
  width: 80%; }
  @media (max-width: 767px) {
    .container-wrapper {
      width: 100%; } }

.container-body {
  background-image: linear-gradient(0deg, #fff, #04675326);
  padding: 0 5%;
  overflow: auto;
  height: calc(100% - 104px); }

.container-subtitle {
  margin: 20px 5px;
  color: #114a8b;
  font-size: 15px; }

.container-list-benif {
  padding: 10px; }

.container-item-benif {
  cursor: pointer;
  overflow: auto;
  margin: 5px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 0 0 #039c7d;
  background-color: #cfeee8;
  color: #046753;
  font-weight: 700;
  transition: all 0.2s;
  padding: 10px; }
  .container-item-benif:hover {
    background-color: #a7e6d9; }

.container-benif-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #046753;
  box-shadow: 1px 1px 3px black;
  margin: 3px;
  text-align-last: center;
  font-size: 12px;
  cursor: pointer;
  float: right;
  color: #ecfdf9; }

.container-benif-label {
  float: left; }

.container-button-add {
  background-color: #046753;
  box-shadow: 0 4px 0 0 #006450;
  color: #ecfdf9; }
  .container-button-add:hover {
    background-color: #049c7e; }

.doctor_container-widget {
  background-color: #039c7d1a;
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-shadow: 1px 1px 2px #a0cdc2; }
  @media (max-width: 767px) {
    .doctor_container-widget {
      height: 30px;
      width: 30px;
      padding: 5px; } }

.doctor_container-label {
  text-align-last: center;
  color: #046753;
  margin-top: 0px;
  font-size: 8px; }
  @media (max-width: 767px) {
    .doctor_container-label {
      display: none; } }

.doctor_container-img {
  height: 30px;
  width: 30px; }
  @media (max-width: 767px) {
    .doctor_container-img {
      height: 20px;
      width: 20px; } }

.doctor_flex {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: #0467530f;
  padding: 20px; }

.doctor_width {
  width: 100%;
  position: relative; }

.doctor_margin {
  margin: 0 23px; }
  @media (max-width: 767px) {
    .doctor_margin {
      margin: 0 15px; } }

.doctor_input {
  width: 100%;
  border: 1px solid #046753;
  padding: 5px; }
  .doctor_input:focus-visible {
    outline: none; }

.doctor_icon-container {
  position: absolute;
  right: 9px;
  top: 4px;
  width: 20px;
  height: 20px; }

.doctor_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #046753; }

.doctor_card {
  display: flex;
  position: relative;
  width: 100%; }
  @media (max-width: 767px) {
    .doctor_card {
      min-height: 110px; } }
  .doctor_card-photo {
    height: 100px;
    width: 100px; }
    @media (max-width: 767px) {
      .doctor_card-photo {
        height: 60px;
        width: 60px;
        align-self: center; } }
  .doctor_card-detail {
    align-self: center;
    margin-left: 30px; }
  .doctor_card-title {
    font-size: 28px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .doctor_card-title {
        font-size: 24px; } }
  .doctor_card-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    color: #006450; }

.navbar-dark .navbar-nav .nav-link {
  font-weight: 700;
  font-family: fangsong;
  font-size: 16px;
  color: #d9eff1 !important; }

.navbar-dark .navbar-brand {
  color: #e0b430f2 !important;
  font-weight: bold; }

.auto-width {
  width: auto;
  height: 50px;
  margin-right: 10px; }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 260px; } }

.label-input {
  align-self: center;
  margin: 0 10px 0 0;
  min-width: 110px; }

.flex {
  display: flex;
  padding: 5px 0; }

.warning__header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 1px solid #203247; }

.warning__body {
  height: 60%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.warning__footer {
  height: 20%;
  display: flex;
  justify-content: space-evenly; }

.empty {
  text-align-last: center;
  margin: 12px;
  background: white;
  border: 1px solid #cdc9c9;
  padding: 24px; }
  .empty_icon {
    font-size: 32px;
    color: #046753; }
  .empty_text {
    font-size: 20px;
    color: #046753; }

.container-wrapper {
  background-color: white;
  box-shadow: 2px 2px 10px #d8d8d8;
  max-width: 1200px;
  width: 80%; }
  @media (max-width: 767px) {
    .container-wrapper {
      width: 100%; } }

.container-body {
  padding: 0 5%;
  overflow: auto;
  height: calc(100% - 104px); }

.container-return-action {
  font-size: 18px;
  padding: 5px;
  font-weight: bold;
  color: #046753;
  cursor: pointer;
  color: #ecfdf9;
  display: flex;
  background: #046753e3;
  align-items: center; }
  .container-return-action:hover {
    color: #049c7e; }
  @media (max-width: 767px) {
    .container-return-action {
      font-size: 15px; } }

.bold {
  font-weight: bold; }

.container-subtitle {
  margin: 20px 5px;
  color: #114a8b;
  font-size: 15px; }
  @media (max-width: 767px) {
    .container-subtitle {
      margin: 7px 5px;
      font-size: 12px; } }

.container-right-actions {
  color: #046753;
  font-weight: bold;
  margin: 32px 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  font-weight: 600;
  width: 28rem;
  max-height: 6.5rem;
  border-radius: 1rem;
  background-color: white;
  cursor: pointer;
  border: 1px solid #e1eaf0; }
  @media (max-width: 767px) {
    .container-right-actions {
      font-size: 12px;
      width: 18rem;
      margin: 24px 1px; } }
  .container-right-actions_bloc-left {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #04675314;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px; }
    .container-right-actions_bloc-left_inside {
      width: 24px;
      height: 24px;
      border-radius: 0.3rem;
      overflow: hidden;
      background-color: #11826b;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white; }
  .container-right-actions:hover {
    box-shadow: 0 16px 24px rgba(51, 51, 51, 0.08); }

.container-filters-top {
  overflow: auto;
  height: 70px;
  display: flex;
  justify-content: center;
  margin: 10px 0; }
  @media (max-width: 767px) {
    .container-filters-top {
      height: 70px; } }

.container-filter-top {
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  background-color: #00000014;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  color: #036753;
  text-align: center;
  border: 1px solid #c9c9c9; }
  .container-filter-top:hover {
    background-color: #00000021 !important; }
  @media (max-width: 767px) {
    .container-filter-top {
      min-width: 55px;
      font-size: 10px;
      width: 25%;
      padding: 0 3px;
      display: grid; } }

.container-filter-top-actif {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  background-color: #11826b;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px; }
  @media (max-width: 767px) {
    .container-filter-top-actif {
      min-width: 55px;
      font-size: 10px;
      width: 25%;
      padding: 0 3px;
      display: grid; } }

.container-tabs-top {
  overflow: auto;
  display: flex;
  justify-content: center;
  padding: 5px 0; }

.container-tab-top {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  color: #036753;
  text-align: center;
  border: 1px solid #c9c9c9;
  font-size: 12px;
  padding: 10px;
  line-height: 1;
  background-color: #00000014; }
  .container-tab-top:hover {
    background-color: #00000021 !important; }
  @media (max-width: 767px) {
    .container-tab-top {
      min-width: 55px;
      width: 25%;
      font-size: 10px;
      padding: 5px; } }

.container-tab-top-actif {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  background-color: #11826b;
  color: white;
  text-align: center;
  border: 1px solid #036753;
  font-size: 12px;
  padding: 10px;
  line-height: 1; }
  @media (max-width: 767px) {
    .container-tab-top-actif {
      min-width: 55px;
      width: 25%;
      font-size: 10px;
      padding: 5px; } }

.min-height-40 {
  min-height: 200px;
  background-color: #ecfdf9; }

@media (max-width: 767px) {
  .small-text {
    font-size: 8px !important; } }

.container-widget-line {
  display: flex;
  margin: 1%; }

.container-widget {
  background-color: #039c7d1a;
  cursor: pointer;
  height: 200px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-shadow: 1px 1px 2px #a0cdc2; }
  @media (max-width: 767px) {
    .container-widget {
      height: 100px; } }
  .container-widget:hover {
    background-color: #039c7d9e !important; }

.container-img {
  height: 100px;
  width: 100px; }
  @media (max-width: 767px) {
    .container-img {
      height: 60px;
      width: 60px; } }

.container-label {
  text-align-last: center;
  color: #046753;
  margin-top: 10px; }
  @media (max-width: 767px) {
    .container-label {
      font-size: 8px; } }

.hidden .MuiBadge-badge {
  visibility: hidden; }

.badge-green .MuiBadge-badge {
  background-color: orange;
  color: white; }

.MuiTabs-root {
  background-color: #ecfdf9; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.btn-action {
  display: flex;
  align-items: center;
  background: #046753;
  color: #def7f2;
  padding: 5px;
  box-shadow: 1px 1px 1px #add9ff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 3px;
  font-weight: bold;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
  border-radius: 4px;
  color: #ecfdf9;
  margin: 20px 3px 20px 0; }
  .btn-action svg {
    margin: 0 5px; }
  .btn-action:hover {
    color: #049c7e; }
  @media (max-width: 767px) {
    .btn-action {
      font-size: 10px !important; } }

.all_width {
  width: 100%; }

.download-file {
  cursor: pointer;
  text-decoration: underline; }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 300px; } }

.filter__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px; }
  .filter__container--item {
    background-color: #e7e7e7;
    height: 50px;
    margin: 5px;
    display: flex;
    padding: 5px;
    align-items: center;
    width: 100px;
    justify-content: center;
    cursor: pointer; }
    .filter__container--item:hover {
      opacity: 0.8; }
  .filter__container-actif {
    background-color: #ade1d8;
    font-weight: bold; }

.content-select {
  width: 200px; }

.select__container {
  background-color: #f9f9f9;
  height: 134px; }

.Autocomplete .MuiInputBase-root {
  height: 50px; }

.select__container_fix {
  background-color: #f9f9f9;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold; }

.input-min-width {
  min-width: 95px !important; }

.file__header-title {
  padding: 15px 28px;
  font-weight: 600;
  font-size: 15px; }

.file__body {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .file__body {
      height: calc(100% - 60px); } }
  .file__body-description {
    color: #9d9d9d;
    padding: 0 28px 20px 28px; }
    @media (max-width: 767px) {
      .file__body-description {
        font-size: 10px; } }
  .file__body--input {
    padding: 0px 15px;
    display: grid; }
    .file__body--input-label {
      font-size: 12px;
      margin-bottom: 0px; }
    .file__body--input-input {
      height: 30px;
      width: 100%;
      padding-left: 10px;
      position: relative;
      font-size: 12px; }
    .file__body--input-inputDesc {
      height: 30px;
      width: 100%;
      padding-left: 10px;
      position: relative;
      font-size: 20px; }
    .file__body--input-container {
      height: 35px;
      position: relative; }

.center-loader {
  display: flex;
  justify-content: center; }

.image-content {
  width: 100%;
  height: auto; }

.pdf-content {
  width: 100%;
  height: 100%; }

.react-pdf__Page__canvas {
  width: 100% !important; }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px;
  padding-top: 10px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 260px; } }

.card-container {
  background-color: white;
  min-width: 350px;
  max-width: 600px;
  height: auto;
  border-radius: 14px 14px 0 0;
  margin: auto; }
  @media (max-width: 767px) {
    .card-container {
      min-width: 150px; } }

.header {
  background: #dff9f3;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px; }

.img {
  margin: auto;
  width: 100px;
  border: solid white 4px;
  border-radius: 50%;
  margin-top: 75px; }

.bold-text {
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
  padding: 10px 20px 0px 20px; }

.normal-text {
  font-weight: normal;
  font-size: 0.95rem;
  color: gray;
  text-align: center;
  padding-bottom: 10px; }

/* SOCIAL STATS */
.smaller-text {
  font-weight: normal;
  font-size: 0.7rem;
  color: gray;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 20px;
  line-height: 5px; }

.social-container {
  display: flex;
  border-top: solid #cecece 1px;
  text-align: center; }

.followers {
  flex: 1 1; }

.likes {
  flex: 1 1; }

.photos {
  flex: 1 1; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.result__container {
  background-color: #ecfdf9; }

.result__title {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #046753;
  color: #eafcf7;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 4px; }

.result__category {
  margin: 10px;
  padding: 5px;
  font-weight: bold;
  color: #046753;
  border-bottom: 1px solid;
  text-align-last: center; }

.result__exam-name {
  margin: 0px 10px;
  padding: 5px;
  font-weight: bold;
  color: #046753; }

.result__exam-name-comment {
  margin: 0px 10px;
  font-size: 12px;
  color: #7195b3; }

.result__result-line {
  display: flex;
  justify-content: space-around;
  margin: 10px; }

.graph-button {
  cursor: pointer; }

.graph__header {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center; }

.graph__body {
  margin: auto;
  width: 80%;
  height: 80%; }

.graph__footer {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center; }

.download-file {
  cursor: pointer;
  text-decoration: underline; }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 260px; } }

.text-area {
  display: grid;
  margin: 10px 0px; }

.result_container {
  margin-top: 15px;
  padding: 5% 10px;
  background: #f7f7f7; }
  .result_container--title {
    font-weight: bold;
    text-align: center; }

.margin-text {
  margin: 10px; }

.margin-date {
  margin: 10px 0; }

.report-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .report-container__left {
    padding-top: 50px;
    width: 10%;
    z-index: 1; }
    .report-container__left--button {
      display: flex;
      color: #046753;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin: 32px;
      cursor: pointer; }
      .report-container__left--button:hover {
        opacity: 0.8; }
  .report-container__right {
    width: 0%; }
    .report-container__right--button {
      max-width: 150px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      margin: 32px;
      border: 1px solid #046753;
      color: #046753;
      box-sizing: border-box;
      background-color: white;
      border-radius: 8px;
      cursor: pointer; }
  .report-container__body {
    overflow: auto;
    width: 100%; }
    .report-container__body--header {
      background: white;
      margin-right: 10%;
      padding: 32px;
      margin-top: 32px;
      margin-bottom: 32px;
      border-radius: 2px; }
      .report-container__body--header-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: var(--accent-950);
        margin: 32px auto; }
        @media (max-width: 767px) {
          .report-container__body--header-title {
            font-size: 18px; } }
      .report-container__body--header-bloc {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--accent-950);
        margin: 4px 0px; }
      .report-container__body--header-cell {
        display: flex;
        width: 100%;
        justify-content: space-around;
        border: 1px solid #333333; }
        .report-container__body--header-cell:not(:last-child) {
          border-bottom: none; }
      .report-container__body--header-halfcell {
        width: 50%;
        border-right: 1px solid #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px; }
        .report-container__body--header-halfcell:last-child {
          border-right: none; }
        @media (max-width: 767px) {
          .report-container__body--header-halfcell {
            padding: 8px; } }
    .report-container__body--legend {
      margin-top: 30px; }
      .report-container__body--legend-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--accent-950);
        margin: 15px 0px; }
      .report-container__body--legend-symbol {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--accent-950);
        margin: 4px 0px;
        display: flex; }
    .report-container__body__footer {
      padding-bottom: 32px;
      margin: 32px 0; }
    .report-container__body--text {
      font-size: 14px;
      margin-left: 10px; }
      @media (max-width: 767px) {
        .report-container__body--text {
          font-size: 12px;
          margin-left: 5px; } }
  .report-container__footer {
    padding-bottom: 32px;
    margin: 32px 0; }

.h1-report {
  font-size: 24px; }

.h2-report {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--accent-950);
  margin-top: 2px; }
  @media (max-width: 767px) {
    .h2-report {
      font-size: 12px; } }

.h2-module {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--accent-950);
  margin-top: 2px; }
  @media (max-width: 767px) {
    .h2-module {
      font-size: 14px; } }

.h3-report {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 22px;
  color: var(--accent-950);
  margin: 32px 0px 12px; }
  @media (max-width: 1281px) {
    .h3-report {
      margin: 24px 0px 12px; } }
  @media (max-width: 767px) {
    .h3-report {
      margin: 18px 0px 6px;
      font-size: 16px; } }

.p-report {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  color: var(--accent-950);
  align-self: center;
  font-size: 0.875rem; }
  @media (max-width: 767px) {
    .p-report {
      font-size: 9px; } }

.text-center {
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: 220px; }

.div-center {
  align-self: center;
  padding: 12px 0;
  max-width: 25%; }
  @media (max-width: 767px) {
    .div-center {
      padding: 8px 0; } }

.report-content {
  margin-top: 40px; }
  @media (max-width: 767px) {
    .report-content {
      margin-top: 10px; } }
  .report-content__header {
    background: #046753;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: white;
    padding: 15px;
    direction: rtl;
    letter-spacing: 0.4px;
    border-bottom: 1px solid #333333; }
    @media (max-width: 767px) {
      .report-content__header {
        font-size: 12px;
        padding: 10px; } }
  .report-content__body {
    display: flex;
    border: 1px solid #333333;
    border-top: none; }
    .report-content__body--text {
      width: 80%;
      padding: 20px;
      border-right: 1px solid #333333; }
      @media (max-width: 767px) {
        .report-content__body--text {
          padding: 8px;
          font-size: 12px; } }
    .report-content__body--value {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center; }
  .report-content__footer {
    background: var(--primary-50);
    border: 1px solid #333333;
    border-top: none;
    padding: 20px; }
    @media (max-width: 767px) {
      .report-content__footer {
        padding: 8px;
        font-size: 12px; } }

.icon-status {
  width: 35px;
  height: 35px;
  align-self: center;
  margin: 0; }

.icon-exit {
  width: 15px;
  height: 15px;
  align-self: center;
  margin-left: 5px; }

.icon-legend {
  width: 25px;
  height: 25px;
  align-self: center;
  margin-right: 4px; }

.icon-NA {
  font-size: 12px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center; }

.icon-NA-status {
  width: 35px;
  align-self: center;
  font-size: 15px;
  font-weight: bold; }

.opacity {
  opacity: 0; }

.apexcharts-toolbar {
  display: none; }

.chart {
  justify-content: center; }

.width_button {
  float: right;
  min-width: 150px;
  background-color: var(--primary-850);
  font-family: Roboto;
  font-weight: 600;
  color: white; }
  .width_button:hover {
    background-color: var(--primary-870); }

.center-spinner {
  margin: auto; }

.hide-graph {
  opacity: 0;
  position: absolute; }

.logo-sihati {
  color: #e0b430f2 !important;
  font-weight: bold; }

.download-button {
  background: white;
  padding: 5px;
  box-shadow: 1px 1px 1px #036753;
  max-width: 100px; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 260px; } }

.margin-left {
  margin-left: 20px !important; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.download-file {
  cursor: pointer;
  text-decoration: underline; }

.right-status {
  text-align: right; }

.status-progress {
  color: orange;
  font-weight: bold !important; }

.status-done {
  color: green;
  font-weight: bold !important; }

.status-todo {
  color: red;
  font-weight: bold !important; }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 260px; } }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 260px; } }

.margin-left {
  margin-left: 20px !important; }

.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1; }

.MuiInputBase-root {
  height: 30px; }

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px; }

.content-modal {
  height: 300px;
  padding-top: 10px; }
  @media (max-width: 767px) {
    .content-modal {
      height: 260px; } }

.urgent-contact {
  overflow: auto;
  margin: 5px 0;
  border-radius: 8px;
  /* box-shadow: 0px 1px 1px #0467532b; */
  border: 2px solid #04675314;
  background-color: #ecfdf9;
  font-weight: 700;
  transition: all 0.2s;
  padding: 10px; }

.margin-left {
  margin-left: 20px !important; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.link {
  cursor: pointer; }

.right-status {
  text-align: right; }

.status-progress {
  color: orange;
  font-weight: bold !important; }

.status-done {
  color: green;
  font-weight: bold !important; }

.MuiTabs-centered {
  justify-content: center;
  overflow-x: auto;
  background-color: #dff9f3; }

.MuiTabs-flexContainer {
  display: block !important;
  text-align-last: center; }

.container-widget-line-info {
  margin: 1px; }
  @media (max-width: 767px) {
    .container-widget-line-info {
      display: contents; } }

.container-widget-info-img {
  height: 60px;
  width: 60px;
  display: flex; }

.container-widget-info {
  cursor: pointer;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #11826b;
  margin: 1px; }
  @media (max-width: 767px) {
    .container-widget-info {
      height: 100px;
      width: 100%; } }
  .container-widget-info:hover {
    opacity: 0.9; }

.container-img-info {
  width: auto;
  height: 90%;
  margin: auto; }
  @media (max-width: 767px) {
    .container-img-info {
      width: auto;
      height: 90%; } }

.container-label-info {
  width: 70%;
  text-align-last: center;
  padding: 0 5px;
  text-align: center; }

.size-24 {
  font-size: 24px;
  color: #d5b032;
  font-weight: bold; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

@media (max-width: 767px) {
  .MuiTableCell-root {
    padding: 3px !important;
    max-width: 55px;
    font-size: 10px !important; } }

.download-file {
  cursor: pointer;
  text-decoration: underline; }

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important; }

.download-file {
  cursor: pointer;
  text-decoration: underline; }

.login {
  width: 100%; }
  .login__header {
    height: 70px;
    background-color: #ecfdf9; }
  .login__image {
    text-align: center; }
  .login__body {
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center; }
  .login__submit {
    cursor: pointer; }
  .login__form {
    width: 400px;
    height: 400px;
    padding: 20px;
    box-shadow: 2px 2px 10px #d8d8d8; }

.login {
  width: 100%; }
  .login__header {
    height: 70px;
    background-color: #ecfdf9; }
  .login__image {
    text-align: center; }
  .login__body {
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center; }
  .login__submit {
    cursor: pointer; }
  .login__form {
    width: 400px;
    height: 400px;
    padding: 20px;
    box-shadow: 2px 2px 10px #d8d8d8; }

