// override

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important;
}
.download-file {
  cursor: pointer;
  text-decoration: underline;
}
.right-status {
  text-align: right;
}
.status-progress {
  color: orange;
  font-weight: bold !important;
}
.status-done {
  color: green;
  font-weight: bold !important;
}
.status-todo {
  color: red;
  font-weight: bold !important;
}
