// override

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important;
}
.result {
  &__container {
    background-color: #ecfdf9;
  }
  &__title {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #046753;
    color: #eafcf7;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    border-radius: 4px;
  }
  &__category {
    margin: 10px;
    padding: 5px;
    font-weight: bold;
    color: #046753;
    border-bottom: 1px solid;
    text-align-last: center;
  }
  &__exam-name {
    margin: 0px 10px;
    padding: 5px;
    font-weight: bold;
    color: #046753;
  }
  &__exam-name-comment {
    margin: 0px 10px;
    font-size: 12px;
    color: #7195b3;
  }
  &__result-line {
    display: flex;
    justify-content: space-around;
    margin: 10px;
  }
}
.graph-button {
  cursor: pointer;
}
.graph {
  &__header {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__body {
    margin: auto;
    width: 80%;
    height: 80%;
  }
  &__footer {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.download-file {
  cursor: pointer;
  text-decoration: underline;
}
