// override

.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important;
}
.MuiTableCell-root {
  @media (max-width: 767px) {
    padding: 3px !important;
    max-width: 55px;
    font-size: 10px !important;
  }
}
.download-file {
  cursor: pointer;
  text-decoration: underline;
}
