.ReactModal__Overlay--after-open {
  background-color: rgba(37, 40, 42, 0.7) !important;
  z-index: 1;
}
.MuiInputBase-root {
  height: 30px;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.content-modal {
  height: 300px;
  @media (max-width: 767px) {
    height: 260px;
  }
}
.label-input {
  align-self: center;
  margin: 0 10px 0 0;
  min-width: 110px;
}
.flex {
  display: flex;
  padding: 5px 0;
}
