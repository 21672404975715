.warning {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    border-bottom: 1px solid #203247;
  }
  &__body {
    height: 60%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__footer {
    height: 20%;
    display: flex;
    justify-content: space-evenly;
  }
}
.empty {
  text-align-last: center;
  margin: 12px;
  background: white;
  border: 1px solid #cdc9c9;
  padding: 24px;
  &_icon {
    font-size: 32px;
    color: #046753;
  }
  &_text {
    font-size: 20px;
    color: #046753;
  }
}
