.navbar-dark .navbar-nav .nav-link {
  // color: #f6e266 !important;
  font-weight: 700;
  font-family: fangsong;
  // font-style: italic;
  font-size: 16px;

  color: #d9eff1 !important;
}
.navbar-dark .navbar-brand {
  color: #e0b430f2 !important;
  font-weight: bold;
}
.auto-width {
  width: auto;
  height: 50px;
  margin-right: 10px;
}
