// colors Var
$black-600: #1c1c1c;
$black-550: #33373a;
$black-500: #24282a;
$black-400: #424242;
$black-350: #757575; // text
$black-300: #bdbcbc;

$gray-400: #d2d2d2;

$blue-700: #0b223370; // when back ground is dark
$blue-750: #0b2233d6; // when back ground is white
$yellow-700: #ffebcd;
