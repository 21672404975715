// override
.MuiTabs-root {
  background-color: #ecfdf9;
}
.MuiAccordionSummary-root {
  background-color: #ecfdf9 !important;
}
.MuiAccordion-rounded {
}
.btn-action {
  display: flex;
  align-items: center;
  background: #046753;
  color: #def7f2;
  padding: 5px;
  box-shadow: 1px 1px 1px #add9ff;
  width: fit-content;
  margin: 3px;
  font-weight: bold;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
  border-radius: 4px;
  color: #ecfdf9;

  margin: 20px 3px 20px 0;
  svg {
    margin: 0 5px;
  }
  &:hover {
    color: #049c7e;
  }
  @media (max-width: 767px) {
    font-size: 10px !important;
  }
}
.all_width {
  width: 100%;
}
.download-file {
  cursor: pointer;
  text-decoration: underline;
}
